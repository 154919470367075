import React from "react";
import "./Footer.css";
import logo from "../../images/logo1.JPG";
import { easeOut, motion } from "framer-motion";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BiLogoInstagram, BiLogoWhatsapp } from "react-icons/bi";

import aml from "../../images/aml.jpg";
import ico from "../../images/ico.jpg";
import prs from "../../images/prs.png";
import company from "../../images/companies-house.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_container">
        <div className="upper">
          <div className="left">
            <div className="logo">
              <motion.img
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.1, ease: easeOut }}
                viewport={{ once: true }}
                src={logo}
                alt=""
              />
              <motion.span
                whileInView={{ x: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.2, ease: easeOut }}
                viewport={{ once: true }}
              ></motion.span>
            </div>
            <motion.p
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.3, ease: easeOut }}
              viewport={{ once: true }}
            >
              <span>
                Fully qualified and regulated professional property company.
              </span>
            </motion.p>
            <div className="list" style={{ marginTop: "20px" }}>
              <motion.h1
                whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.2, ease: easeOut }}
                viewport={{ once: true }}
              >
                Get In Touch
              </motion.h1>
              <motion.span
                whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.3, ease: easeOut }}
                viewport={{ once: true }}
                className="line"
              ></motion.span>
              <ul>
                <motion.li
                  whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.5, ease: easeOut }}
                  viewport={{ once: true }}
                >
                  <AiOutlinePhone />
                  <a href="tel:+4407401802003">(+44) 0740 180 2003</a>
                </motion.li>
                <motion.li
                  whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.6, ease: easeOut }}
                  viewport={{ once: true }}
                >
                  <AiOutlineMail />
                  <a href="mailto:info@colett.co.uk">info@colett.co.uk</a>
                </motion.li>
                <motion.li
                  whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.7, ease: easeOut }}
                  viewport={{ once: true }}
                >
                  <BiLogoInstagram />
                  <a
                    href="https://www.instagram.com/yugal_colettproperties/"
                    target="black"
                  >
                    Yugal Colett Properties
                  </a>
                </motion.li>
                <motion.li
                  whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.8, ease: easeOut }}
                  viewport={{ once: true }}
                >
                  <BiLogoWhatsapp />
                  <a href="https://wa.me/+447401802003" target="black">
                    INNER CIRCLE
                  </a>
                </motion.li>
              </ul>
            </div>
          </div>
          <div className="right">
            <div className="list">
              <motion.h1
                whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.2, ease: easeOut }}
                viewport={{ once: true }}
              >
                Company Details
              </motion.h1>
              <motion.span
                whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                transition={{ duration: 0.3, ease: easeOut }}
                viewport={{ once: true }}
                className="line"
              ></motion.span>
              <ul>
                <motion.li
                  whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.6, ease: easeOut }}
                  viewport={{ once: true }}
                >
                  <img src={ico} alt="ICO" /> <p>C1343956</p>
                </motion.li>
                <motion.li
                  whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.7, ease: easeOut }}
                  viewport={{ once: true }}
                >
                  <img src={prs} alt="PRS" /> <p>PRS035209</p>
                </motion.li>
                <motion.li
                  whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.5, ease: easeOut }}
                  viewport={{ once: true }}
                >
                  <img src={aml} alt="AML" />
                  <p>AML:XVML00000187515</p>
                </motion.li>

                <motion.li
                  whileInView={{ y: [-100, 0], opacity: [0, 1] }}
                  transition={{ duration: 0.4, ease: easeOut }}
                  viewport={{ once: true }}
                >
                  <img src={company} alt="AML" />
                  <p>14311525</p>
                </motion.li>
              </ul>
            </div>
          </div>
        </div>
        <div className="lower">
          <span></span>
          <section>
            <motion.p
              whileInView={{ x: [-100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.2, ease: easeOut }}
              viewport={{ once: true }}
            >
              COLETT: Property Management And Investment
            </motion.p>

            <motion.p
              whileInView={{ x: [100, 0], opacity: [0, 1] }}
              transition={{ duration: 0.3, ease: easeOut }}
              viewport={{ once: true }}
            >
              Copyright &#169; 2021. All rights reserved.
            </motion.p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Footer;
